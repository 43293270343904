<template>
  <v-snackbar
    v-model="snackbar.show"
    :timeout="snackbar.isLoading ? -1 : 4000"
    @click="
      goTo();
      snackbar.show = false;
    "
    :class="{ clickable: snackbar.href }"
    :bottom="!snackbar.important"
    :top="snackbar.important"
    :key="snackbar.important"
  >
    {{ snackbar.message }}
    <template v-slot:action="{ attrs }">
      <v-progress-circular
        v-if="snackbar.isLoading"
        indeterminate
        v-bind="attrs"
        color="primary"
      ></v-progress-circular>
      <v-btn v-else icon color="error" @click.stop="snackbar.show = false">
        <v-icon :color="snackbar.iconColor">{{snackbar.icon ? snackbar.icon : "close"}}</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  data() {
    return {
      snackbar: this.$store.getters.getSnackbarInstance,
    };
  },
  methods: {
    goTo() {
      if (this.snackbar.href) {
        this.$router.push(this.snackbar.href);
      }
    },
  },
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
</style>
