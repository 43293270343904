
<template>
  <v-app>
    <app-menu></app-menu>

    <v-main>
      <router-view></router-view>
    </v-main>

    <Snackbar/>
  </v-app>
</template>

<script>
import Menu from '@/components/Common/Menu.vue'
import Snackbar from '@/components/Common/Snackbar.vue'
export default {
  components: {
    'AppMenu': Menu,
    'Snackbar': Snackbar
  },
}
</script>

<style>
</style>